<template>
  <div class="tasks page">
    <div class="content">
      <div class="page_header">
        <h1>Задачи</h1>
        <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Новая задача</el-button>
      </div>
      <div class="box">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tasks",
  data() {
    return {}
  },
  computed: {},
  methods: {
    create() {

    },
    clear(){

    }
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  },
  beforeDestroy() {
    this.clear()
  }
}
</script>
